var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Browse',{attrs:{"columns":[
    'group',
    'week_day_id',
    'time_from',
    'time_to',
    'note' ],"formats":{
    week_day_id: 'Select*',
    time_from: 'Time',
    time_to: 'Time',
  },"itemsPerPage":"10","searchOnStart":true,"table":"group_schedules"}})}
var staticRenderFns = []

export { render, staticRenderFns }