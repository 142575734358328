<template>
  <Browse
    :columns="[
      'group',
      'week_day_id',
      'time_from',
      'time_to',
      'note',
    ]"
    :formats="{
      week_day_id: 'Select*',
      time_from: 'Time',
      time_to: 'Time',
    }"
    itemsPerPage="10"
    :searchOnStart="true"
    table="group_schedules"
  />
</template>

<script>
import Browse from "@/components/Browse.vue";

export default {
  components: {
    Browse,
  },
};
</script>
